<template>
  <div class="text-container">
    <p>
      Welche Maßnahmen schlagen sich in Ihrer Apotheke sofort auf Umsatz und
      Ergebnis nieder?
    </p>
    <p>
      Welche Maßnahme passt zu Ihnen und Ihrer Strategie? Welches Budget müssen
      Sie planen?
    </p>
    <p>
      Gerne unterbreiten wir Ihnen Vorschläge und teilen unsere Erfahrungen mit
      Ihnen.
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten Sie bei der Durchführung von Maßnahmen, um den Umsatz und das Ergebnis Ihrer Apotheke zu verbessern.",
      },
    ],
  },
};
</script>